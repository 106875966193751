import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'

const style = css`
  ${tw`border border-gray-100 rounded-lg px-7 py-5 shadow-md h-full`}
  ${tw`transition duration-200 ease-in-out transform hover:(-translate-y-1 shadow-xl)`}
  ${tw`bg-gradient-to-br from-white via-white to-purple-50`}
`

function CardContainer({ children, ...props }) {
  return (
    <div css={style} {...props}>
      {children}
    </div>
  )
}

CardContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
}

CardContainer.defaultProps = {}

export default CardContainer
