import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import { MapPinIcon, ClockIcon } from '@heroicons/react/24/outline'
import Container from '../../components/Container'
import CardContainer from '../../components/CardContainer'
import Button from '../../components/Button'

const JobGrid = styled.div`
  ${tw`grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3`}
`

const JazzHr = ({ jazzhr, ...rest }) => {
  // console.log('Jobs Props', jazzhr)
  // console.log('other crap', rest)

  const { totalCount, edges } = jazzhr
  return (
    <Container variant="wide" tw="max-w-screen-2xl">
      {edges.length ? (
        <JobGrid>
          {edges.map(job => (
            <CardContainer key={job.node.id} tw="pb-5 flex flex-col justify-between">
              <div>
                <h3 tw="mt-0 mb-4 text-[20px]">{job.node?.title}</h3>
                <p tw="flex items-center text-sm pb-2">
                  <MapPinIcon tw="text-purple-400 w-5 h-5 inline-block mr-1" /> {job.node?.city ?? 'Hamilton'}, {job.node.state ?? 'ON'},{' '}
                  {job.node?.country_id}
                </p>
                <p tw="flex items-center text-sm">
                  <ClockIcon tw="text-purple-400 w-5 h-5 inline-block mr-1" />
                  {job.node?.type ? `${job.node.type} Position` : ''}
                </p>
              </div>
              <div tw="mt-2 flex justify-end">
                <Button href={job.node?.custom_apply_url} target="_blank" variant="outline" size="xsmall">
                  Apply Today
                </Button>
              </div>
            </CardContainer>
          ))}
        </JobGrid>
      ) : (
        <p>We don't have any job postings at this time</p>
      )}
    </Container>
  )
}

JazzHr.propTypes = {
  //
}

export default JazzHr
